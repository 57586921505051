import React from 'react';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import routes from '../routes';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const RootRouter = () => (
  <Router history={history}>{renderRoutes(routes)}</Router>
);

export default RootRouter;
