import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import ListMenu from '../components/list-menu.js';
import logo from '../assets/img/logo.png';
import FormatAlignLeftOutlinedIcon from '@material-ui/icons/FormatAlignLeftOutlined';

const drawerWidth = 240;
const drawerWidthSmall = 100;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  progress: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgb(6, 180, 184)',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    transition: '0.2s',
    transitionTimingFunction: 'ease-out',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerSmall: {
    width: drawerWidthSmall,
    flexShrink: 0,
    transition: '0.2s',
    transitionTimingFunction: 'ease-out',
  },
  drawerPaperSmall: {
    width: drawerWidthSmall,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#FFFFFF !important',
    color: '#FFFFFF',
  },
  whiteColor: {
    color: '#FFFFFF',
  },
  testeCss: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonUpdateLogo: {
    width: '100%',
    backgroundColor: '#06B4B8',
    color: '#FFFFFF',
    textTransform: 'inherit',
  },
  imageMenu: {
    width: '40%',
  },
  imageMenuSmall: {
    width: '60%',
  },
}));

const LayoutDashboard = props => {
  const { route } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [sizeMenu, setSizeMenu] = useState('full');

  const handleChangeSizeMenu = () => {
    if (sizeMenu === 'full' || sizeMenu === null) {
      setSizeMenu('small');
    } else {
      setSizeMenu('full');
    }
  };

  return (
    <div className={classes.root}>
      <Suspense
        fallback={
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        }
      >
        <CssBaseline />
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar>
            <Tooltip
              title={
                !sizeMenu || sizeMenu === 'full'
                  ? 'Diminuir tamanho do menu'
                  : 'Aumentar tamanho do menu'
              }
            >
              <FormatAlignLeftOutlinedIcon
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleChangeSizeMenu}
              />
            </Tooltip>

            <Typography
              variant='h6'
              noWrap
              style={{
                flexGrow: '1',
                display: 'flex',
                alignItems: 'center',
              }}
            ></Typography>
            <div>
              <Typography
                variant='body1'
                style={{
                  cursor: 'pointer',
                }}
              >
                Sair da conta
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={
            !sizeMenu || sizeMenu === 'full'
              ? classes.drawer
              : classes.drawerSmall
          }
          variant='permanent'
          classes={{
            paper:
              !sizeMenu || sizeMenu === 'full'
                ? classes.drawerPaper
                : classes.drawerPaperSmall,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <ListMenu sizeMenu={sizeMenu} />
            <img
              src={logo}
              className={
                !sizeMenu || sizeMenu === 'full'
                  ? classes.imageMenu
                  : classes.imageMenuSmall
              }
              style={{
                position: 'absolute',
                bottom: '40px',
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
            />
          </div>
        </Drawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
          style={{ padding: '40px' }}
        >
          <Toolbar />
          {renderRoutes(route.routes)}
        </main>
      </Suspense>
    </div>
  );
};

export default LayoutDashboard;
