import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import { Link, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  alignCenterItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  alignCenterItemMax: {
    minWidth: '36px',
  },
}));

const ListMenu = props => {
  const classes = useStyles();
  const location = useLocation();
  const [sizeMenu, setSizeMenu] = useState('full');

  useEffect(() => {
    setSizeMenu(props.sizeMenu);
  }, [props.sizeMenu]);

  return (
    <div className={classes.root}>
      <List
        style={{
          width: '100%',
        }}
      >
        <Link
          to='/'
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <ListItem
            button
            className={
              sizeMenu && sizeMenu === 'small' ? classes.alignCenterItem : ''
            }
          >
            <Tooltip
              title='Dashboard'
              disableHoverListener={
                sizeMenu && sizeMenu === 'small' ? false : true
              }
            >
              <ListItemIcon
                className={
                  sizeMenu && sizeMenu === 'small'
                    ? classes.alignCenterItem
                    : classes.alignCenterItemMax
                }
              >
                <DataUsageOutlinedIcon
                  style={{
                    color:
                      location.pathname === '/dashboard' ? '#06B4B8' : 'gray',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            {(!sizeMenu || sizeMenu === 'full') && (
              <ListItemText
                primary='Dashboard'
                style={{
                  color:
                    location.pathname === '/dashboard' ? '#06B4B8' : 'gray',
                }}
              />
            )}
          </ListItem>
        </Link>
        <Link
          to='/compras'
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <ListItem
            button
            className={
              sizeMenu && sizeMenu === 'small' ? classes.alignCenterItem : ''
            }
          >
            <Tooltip
              title='Compras'
              disableHoverListener={
                sizeMenu && sizeMenu === 'small' ? false : true
              }
            >
              <ListItemIcon
                className={
                  sizeMenu && sizeMenu === 'small'
                    ? classes.alignCenterItem
                    : classes.alignCenterItemMax
                }
              >
                <ShoppingCartOutlinedIcon
                  style={{
                    color:
                      location.pathname === '/compras' ? '#06B4B8' : 'gray',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            {(!sizeMenu || sizeMenu === 'full') && (
              <ListItemText
                primary='Compras'
                style={{
                  color: location.pathname === '/compras' ? '#06B4B8' : 'gray',
                }}
              />
            )}
          </ListItem>
        </Link>
        <Link
          to='/vendas'
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <ListItem
            button
            className={
              sizeMenu && sizeMenu === 'small' ? classes.alignCenterItem : ''
            }
          >
            <Tooltip
              title='Vendas'
              disableHoverListener={
                sizeMenu && sizeMenu === 'small' ? false : true
              }
            >
              <ListItemIcon
                className={
                  sizeMenu && sizeMenu === 'small'
                    ? classes.alignCenterItem
                    : classes.alignCenterItemMax
                }
              >
                <StoreOutlinedIcon
                  style={{
                    color: location.pathname === '/vendas' ? '#06B4B8' : 'gray',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            {(!sizeMenu || sizeMenu === 'full') && (
              <ListItemText
                primary='Vendas'
                style={{
                  color: location.pathname === '/vendas' ? '#06B4B8' : 'gray',
                }}
              />
            )}
          </ListItem>
        </Link>
        <Link
          to='/rede-social'
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <ListItem
            button
            className={
              sizeMenu && sizeMenu === 'small' ? classes.alignCenterItem : ''
            }
          >
            <Tooltip
              title='Rede social'
              disableHoverListener={
                sizeMenu && sizeMenu === 'small' ? false : true
              }
            >
              <ListItemIcon
                className={
                  sizeMenu && sizeMenu === 'small'
                    ? classes.alignCenterItem
                    : classes.alignCenterItemMax
                }
              >
                <ShareOutlinedIcon
                  style={{
                    color:
                      location.pathname === '/rede-social' ? '#06B4B8' : 'gray',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            {(!sizeMenu || sizeMenu === 'full') && (
              <ListItemText
                primary='Rede social'
                style={{
                  color:
                    location.pathname === '/rede-social' ? '#06B4B8' : 'gray',
                }}
              />
            )}
          </ListItem>
        </Link>
        <Link
          to='/meu-perfil'
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <ListItem
            button
            className={
              sizeMenu && sizeMenu === 'small' ? classes.alignCenterItem : ''
            }
          >
            <Tooltip
              title='Meu perfil'
              disableHoverListener={
                sizeMenu && sizeMenu === 'small' ? false : true
              }
            >
              <ListItemIcon
                className={
                  sizeMenu && sizeMenu === 'small'
                    ? classes.alignCenterItem
                    : classes.alignCenterItemMax
                }
              >
                <PermIdentityOutlinedIcon
                  style={{
                    color:
                      location.pathname === '/meu-perfil' ? '#06B4B8' : 'gray',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            {(!sizeMenu || sizeMenu === 'full') && (
              <ListItemText
                primary='Meu perfil'
                style={{
                  color:
                    location.pathname === '/meu-perfil' ? '#06B4B8' : 'gray',
                }}
              />
            )}
          </ListItem>
        </Link>
      </List>
    </div>
  );
};

export default ListMenu;
