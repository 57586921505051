import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from './layouts/LayoutDashboard';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />,
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('./components/pages/Home/index')),
      },
      {
        path: '/meu-perfil',
        exact: true,
        component: lazy(() => import('./components/pages/Settings/index')),
      },
      {
        path: '/compras',
        exact: true,
        component: lazy(() => import('./components/pages/Buy/index')),
      },
      {
        path: '/vendas',
        exact: true,
        component: lazy(() => import('./components/pages/Sell/index')),
      },
    ],
  },
];

export default routes;
